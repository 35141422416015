/*!
 * Bootstrap Grid v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.mt-5 {
  margin-top: 5px !important; }

.pt-5 {
  padding-top: 5px !important; }

.mb-5 {
  margin-bottom: 5px !important; }

.pb-5 {
  padding-bottom: 5px !important; }

.ml-5 {
  margin-left: 5px !important; }

.pl-5 {
  padding-left: 5px !important; }

.mr-5 {
  margin-right: 5px !important; }

.pr-5 {
  padding-right: 5px !important; }

.mt-10 {
  margin-top: 10px !important; }

.pt-10 {
  padding-top: 10px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.ml-10 {
  margin-left: 10px !important; }

.pl-10 {
  padding-left: 10px !important; }

.mr-10 {
  margin-right: 10px !important; }

.pr-10 {
  padding-right: 10px !important; }

.mt-15 {
  margin-top: 15px !important; }

.pt-15 {
  padding-top: 15px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.pb-15 {
  padding-bottom: 15px !important; }

.ml-15 {
  margin-left: 15px !important; }

.pl-15 {
  padding-left: 15px !important; }

.mr-15 {
  margin-right: 15px !important; }

.pr-15 {
  padding-right: 15px !important; }

.mt-20 {
  margin-top: 20px !important; }

.pt-20 {
  padding-top: 20px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.ml-20 {
  margin-left: 20px !important; }

.pl-20 {
  padding-left: 20px !important; }

.mr-20 {
  margin-right: 20px !important; }

.pr-20 {
  padding-right: 20px !important; }

.mt-25 {
  margin-top: 25px !important; }

.pt-25 {
  padding-top: 25px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.pb-25 {
  padding-bottom: 25px !important; }

.ml-25 {
  margin-left: 25px !important; }

.pl-25 {
  padding-left: 25px !important; }

.mr-25 {
  margin-right: 25px !important; }

.pr-25 {
  padding-right: 25px !important; }

.mt-30 {
  margin-top: 30px !important; }

.pt-30 {
  padding-top: 30px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.ml-30 {
  margin-left: 30px !important; }

.pl-30 {
  padding-left: 30px !important; }

.mr-30 {
  margin-right: 30px !important; }

.pr-30 {
  padding-right: 30px !important; }

.mt-35 {
  margin-top: 35px !important; }

.pt-35 {
  padding-top: 35px !important; }

.mb-35 {
  margin-bottom: 35px !important; }

.pb-35 {
  padding-bottom: 35px !important; }

.ml-35 {
  margin-left: 35px !important; }

.pl-35 {
  padding-left: 35px !important; }

.mr-35 {
  margin-right: 35px !important; }

.pr-35 {
  padding-right: 35px !important; }

.mt-40 {
  margin-top: 40px !important; }

.pt-40 {
  padding-top: 40px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.ml-40 {
  margin-left: 40px !important; }

.pl-40 {
  padding-left: 40px !important; }

.mr-40 {
  margin-right: 40px !important; }

.pr-40 {
  padding-right: 40px !important; }

.mt-45 {
  margin-top: 45px !important; }

.pt-45 {
  padding-top: 45px !important; }

.mb-45 {
  margin-bottom: 45px !important; }

.pb-45 {
  padding-bottom: 45px !important; }

.ml-45 {
  margin-left: 45px !important; }

.pl-45 {
  padding-left: 45px !important; }

.mr-45 {
  margin-right: 45px !important; }

.pr-45 {
  padding-right: 45px !important; }

.mt-50 {
  margin-top: 50px !important; }

.pt-50 {
  padding-top: 50px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.ml-50 {
  margin-left: 50px !important; }

.pl-50 {
  padding-left: 50px !important; }

.mr-50 {
  margin-right: 50px !important; }

.pr-50 {
  padding-right: 50px !important; }

.mt-60 {
  margin-top: 60px !important; }

.pt-60 {
  padding-top: 60px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

.pb-60 {
  padding-bottom: 60px !important; }

.ml-60 {
  margin-left: 60px !important; }

.pl-60 {
  padding-left: 60px !important; }

.mr-60 {
  margin-right: 60px !important; }

.pr-60 {
  padding-right: 60px !important; }

.mt-70 {
  margin-top: 70px !important; }

.pt-70 {
  padding-top: 70px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.pb-70 {
  padding-bottom: 70px !important; }

.ml-70 {
  margin-left: 70px !important; }

.pl-70 {
  padding-left: 70px !important; }

.mr-70 {
  margin-right: 70px !important; }

.pr-70 {
  padding-right: 70px !important; }

.mt-80 {
  margin-top: 80px !important; }

.pt-80 {
  padding-top: 80px !important; }

.mb-80 {
  margin-bottom: 80px !important; }

.pb-80 {
  padding-bottom: 80px !important; }

.ml-80 {
  margin-left: 80px !important; }

.pl-80 {
  padding-left: 80px !important; }

.mr-80 {
  margin-right: 80px !important; }

.pr-80 {
  padding-right: 80px !important; }

.mt-90 {
  margin-top: 90px !important; }

.pt-90 {
  padding-top: 90px !important; }

.mb-90 {
  margin-bottom: 90px !important; }

.pb-90 {
  padding-bottom: 90px !important; }

.ml-90 {
  margin-left: 90px !important; }

.pl-90 {
  padding-left: 90px !important; }

.mr-90 {
  margin-right: 90px !important; }

.pr-90 {
  padding-right: 90px !important; }

.mt-100 {
  margin-top: 100px !important; }

.pt-100 {
  padding-top: 100px !important; }

.mb-100 {
  margin-bottom: 100px !important; }

.pb-100 {
  padding-bottom: 100px !important; }

.ml-100 {
  margin-left: 100px !important; }

.pl-100 {
  padding-left: 100px !important; }

.mr-100 {
  margin-right: 100px !important; }

.pr-100 {
  padding-right: 100px !important; }

@-webkit-keyframes scrolls {
  0% {
    top: 8px;
    opacity: 0;
    -webkit-transform: scaleY(1) scaleX(1); }
  5% {
    top: 8px;
    opacity: 0.8;
    -webkit-transform: scaleY(1.2) scaleX(1.2); }
  10% {
    top: 8px;
    opacity: 1;
    -webkit-transform: scaleY(1.6) scaleX(1.6); }
  15% {
    top: 8px;
    opacity: 1;
    -webkit-transform: scaleY(1.2) scaleX(1.2); }
  20% {
    top: 8px;
    opacity: 1;
    -webkit-transform: scaleY(1.2) scaleX(1.2); }
  30% {
    top: 15px;
    opacity: 1;
    -webkit-transform: scaleY(1.2) scaleX(1.2); }
  100% {
    top: 30px;
    opacity: 0;
    -webkit-transform: scaleY(1.2) scaleX(0.8); } }

html, body {
  font-family: 'Open Sans', sans-serif;
  color: #383838;
  height: 100%;
  margin: 0;
  position: relative;
  font-size: 1rem; }

html {
  margin-top: 0; }

body {
  padding-top: 70px; }

p {
  font-size: 1rem;
  line-height: 1.8rem; }

a {
  color: #E32219;
  transition: .2s ease all; }
  a:hover {
    text-decoration: underline; }

img {
  max-width: 100%; }

h1, h2, h3, h4 {
  font-weight: 700;
  margin: 0 0 15px 0; }

h1 {
  font-size: 1.5rem; }

h2 {
  font-size: 1.4rem; }

h3 {
  font-size: 1.3rem; }

h4 {
  font-size: 1.3rem; }

h5 {
  font-size: 1.2rem; }

.nav {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  z-index: 3;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background-color: #383838;
  -webkit-box-shadow: 1px 0px 3px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 1px 0px 3px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 0px 3px 0px rgba(0, 0, 0, 0.05);
  padding: 0;
  height: 70px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
  .nav .nav__menu {
    position: absolute;
    top: 70px;
    display: none;
    width: 66.66667%; }
    @media (max-width: 767.98px) {
      .nav .nav__menu {
        width: 100%; } }
    .nav .nav__menu ul {
      margin: 0;
      padding: 40px 0;
      list-style: none;
      display: inline-block;
      width: 370px;
      background-color: #383838;
      border-right: 1px solid #606060;
      border-bottom: 1px solid #606060; }
      @media (max-width: 767.98px) {
        .nav .nav__menu ul {
          width: 100%; } }
      .nav .nav__menu ul li a {
        display: block;
        text-decoration: none;
        color: #ffffff;
        padding: 7px 15px;
        font-size: 1rem; }
        .nav .nav__menu ul li a span {
          display: inline-block;
          margin-left: 10px;
          vertical-align: middle;
          transition: .2s ease transform; }
          @media (max-width: 767.98px) {
            .nav .nav__menu ul li a span {
              display: none; } }
          .nav .nav__menu ul li a span i {
            height: 12px; }
      .nav .nav__menu ul li:hover > a, .nav .nav__menu ul li.parent.open > a {
        color: #E32219; }
        .nav .nav__menu ul li:hover > a span, .nav .nav__menu ul li.parent.open > a span {
          transform: rotate(180deg); }
      @media (min-width: 768px) {
        .nav .nav__menu ul li:hover ul, .nav .nav__menu ul li.parent.open ul {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex; } }
      .nav .nav__menu ul li.active > a {
        font-weight: 700; }
      .nav .nav__menu ul li ul {
        position: absolute;
        right: 0;
        background-color: #2f2e2e;
        top: 0;
        display: none;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        height: 100%;
        width: auto;
        padding: 40px 45px;
        left: 370px;
        align-content: flex-start; }
        .nav .nav__menu ul li ul li {
          width: 50%; }
          .nav .nav__menu ul li ul li:first-child {
            width: 100%;
            display: block;
            color: #ffffff;
            font-weight: 600;
            font-size: 1.15rem;
            margin-bottom: 5px; }
          .nav .nav__menu ul li ul li a {
            padding: 7px 0; }
  .nav .nav__left {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    height: 100%; }
    .nav .nav__left .nav__trigger {
      color: #ffffff;
      height: 100%;
      padding: 0 15px;
      border-right: 1px solid rgba(255, 255, 255, 0.2);
      cursor: pointer;
      transition: background-color .2s ease; }
      .nav .nav__left .nav__trigger:hover {
        background-color: #E32219; }
      .nav .nav__left .nav__trigger i {
        font-size: 1.2rem;
        height: 16px;
        width: 17px;
        text-align: center; }
      .nav .nav__left .nav__trigger span {
        margin-left: 8px; }
      .nav .nav__left .nav__trigger .fa-times {
        display: none; }
    .nav .nav__left .nav__search {
      color: #E32219;
      margin-left: 15px; }
      @media (max-width: 767.98px) {
        .nav .nav__left .nav__search {
          display: none; } }
      .nav .nav__left .nav__search input {
        background-color: transparent;
        box-shadow: none;
        border: 0;
        color: #ffffff;
        outline: none;
        font-size: 1rem; }
        .nav .nav__left .nav__search input::placeholder {
          color: rgba(255, 255, 255, 0.9); }
  .nav .nav__right {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    padding-right: 15px;
    height: 35px; }
    .nav .nav__right .nav__item {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex; }
      @media only screen and (max-width: 1080px) {
        .nav .nav__right .nav__item {
          display: none; } }
      .nav .nav__right .nav__item ul {
        padding: 0;
        margin: 0;
        list-style: none;
        font-size: .9rem; }
        .nav .nav__right .nav__item ul li {
          display: inline-block; }
          .nav .nav__right .nav__item ul li a {
            font-size: .9rem;
            text-decoration: none; }
            .nav .nav__right .nav__item ul li a:hover {
              color: #E32219; }
      .nav .nav__right .nav__item .contact li:not(:last-child) {
        margin-right: 10px; }
      .nav .nav__right .nav__item .contact li a {
        color: #ffffff; }
        .nav .nav__right .nav__item .contact li a i {
          margin-right: 5px;
          color: #E32219; }
      .nav .nav__right .nav__item .social li:not(:first-child) {
        margin-left: 5px; }
      .nav .nav__right .nav__item .social li a {
        font-size: 1.3rem; }
        .nav .nav__right .nav__item .social li a:hover {
          color: #ffffff; }
      .nav .nav__right .nav__item .links a,
      .nav .nav__right .nav__item .contact a {
        color: #ffffff; }
        .nav .nav__right .nav__item .links a:hover,
        .nav .nav__right .nav__item .contact a:hover {
          color: #E32219; }
      .nav .nav__right .nav__item .language li:not(:first-child) {
        margin-left: 3px; }
      .nav .nav__right .nav__item .language li.active a {
        color: #ffffff; }
      .nav .nav__right .nav__item .language li a {
        text-transform: uppercase;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.65); }
        .nav .nav__right .nav__item .language li a:hover {
          color: #ffffff; }
      .nav .nav__right .nav__item > div:not(:first-child) {
        margin-left: 15px; }
        .nav .nav__right .nav__item > div:not(:first-child)::before {
          content: '';
          height: 10px;
          border-left: 1px solid #E8E8E8;
          margin-right: 15px;
          vertical-align: middle; }
        .nav .nav__right .nav__item > div:not(:first-child) ul {
          display: inline-block; }
    .nav .nav__right .logo {
      width: 130px;
      position: relative;
      display: block;
      margin-left: 30px; }
      @media (max-width: 575.98px) {
        .nav .nav__right .logo {
          width: 100px; } }
      .nav .nav__right .logo a {
        display: block;
        position: absolute; }
        .nav .nav__right .logo a img {
          vertical-align: text-bottom; }
  .nav.active .nav__menu {
    display: block; }
  .nav.active .nav__trigger {
    background-color: #E32219; }
    .nav.active .nav__trigger .fa-bars {
      display: none; }
    .nav.active .nav__trigger .fa-times {
      display: block; }

.qr__files {
  margin-top: 30px; }
  .qr__files .item a {
    background-color: #ECEDF2;
    padding: 30px 15px;
    display: block;
    color: #383838;
    min-height: 160px;
    margin-bottom: 15px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    text-decoration: none; }
    .qr__files .item a:hover {
      color: #E32219; }
    .qr__files .item a i {
      font-size: 50px; }
    .qr__files .item a span {
      margin-top: auto;
      font-size: 15px; }

.qr .item {
  margin-bottom: 15px; }

.qr .item .title {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  background-color: #ECEDF2; }

.qr .item .title a {
  color: #333;
  text-decoration: none; }

.qr .item:hover .title a {
  color: #eee; }

.qr .item img {
  border: 1px solid #eee;
  width: 100%;
  cursor: pointer;
  height: auto;
  vertical-align: text-bottom; }

.qr .item .title .edit-btn {
  padding: 14px;
  display: inline-block;
  width: 50px;
  text-align: center;
  background-color: #383838;
  color: #fff;
  cursor: pointer; }

.qr .item .title a {
  padding: 10px;
  display: inline-block;
  text-align: center;
  font-size: 17px;
  text-transform: uppercase;
  color: #555;
  font-weight: 600; }

.qr .item:hover .title a {
  background-color: transparent; }

.qr .item:hover img,
.qr .item:hover .title {
  border-color: #333;
  background-color: #333;
  color: #eee; }

.wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: block; }
  .wrapper.slider_spacing {
    padding-top: 600px; }
    @media (max-width: 1199.98px) {
      .wrapper.slider_spacing {
        padding-top: 500px; } }
    @media (max-width: 767.98px) {
      .wrapper.slider_spacing {
        padding-top: 300px; } }
    @media (max-width: 575.98px) {
      .wrapper.slider_spacing {
        padding-top: 180px; } }
    .wrapper.slider_spacing > *:not(.banner__slider) {
      background-color: #ffffff;
      z-index: 1;
      position: relative; }

.search__results {
  list-style: none;
  padding: 0;
  margin: 0; }
  .search__results .result__item {
    margin-bottom: 30px; }
    .search__results .result__item:hover p {
      color: #E32219; }
    .search__results .result__item h5 {
      margin: 0 0 15px;
      font-size: 1.1rem; }
    .search__results .result__item p {
      margin: 0;
      color: #383838;
      transition: color .3s ease-in-out; }
    .search__results .result__item a {
      text-decoration: none; }

.slick-slider button {
  background-color: rgba(51, 51, 51, 0.7);
  border: 0;
  box-shadow: none;
  height: 45px;
  width: 45px;
  font-size: 1.8rem;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  z-index: 1; }
  .slick-slider button:hover {
    background-color: #333; }
  .slick-slider button i {
    vertical-align: middle; }
  .slick-slider button.slick-arrow {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
    .slick-slider button.slick-arrow.slick-prev {
      left: 0; }
    .slick-slider button.slick-arrow.slick-next {
      right: 0; }

.banner__slider {
  width: 100%;
  height: 100%;
  max-height: 600px;
  overflow: hidden;
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  z-index: 0; }
  @media (max-width: 1199.98px) {
    .banner__slider {
      max-height: 500px; } }
  @media (max-width: 767.98px) {
    .banner__slider {
      max-height: 300px; } }
  @media (max-width: 575.98px) {
    .banner__slider {
      max-height: 180px; } }
  .banner__slider .slick-list,
  .banner__slider .slick-track {
    height: 100%; }
  .banner__slider .slider__item {
    position: relative;
    height: 100%; }
    .banner__slider .slider__item .slider__thumb {
      height: 100%;
      position: relative;
      overflow: hidden; }
      .banner__slider .slider__item .slider__thumb img {
        flex-shrink: 0;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover; }
    .banner__slider .slider__item .slider__content {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0; }
      .banner__slider .slider__item .slider__content span {
        color: #ffffff;
        text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5); }
        .banner__slider .slider__item .slider__content span:first-child {
          font-size: 4.4rem;
          margin: 0;
          font-weight: 600; }
          @media (max-width: 1199.98px) {
            .banner__slider .slider__item .slider__content span:first-child {
              font-size: 3rem; } }
          @media (max-width: 767.98px) {
            .banner__slider .slider__item .slider__content span:first-child {
              font-size: 2rem; } }
        .banner__slider .slider__item .slider__content span:nth-child(n+2) {
          font-size: 1.3rem; }
          @media (max-width: 1199.98px) {
            .banner__slider .slider__item .slider__content span:nth-child(n+2) {
              font-size: 1.2rem; } }
          @media (max-width: 767.98px) {
            .banner__slider .slider__item .slider__content span:nth-child(n+2) {
              display: none; } }
          .banner__slider .slider__item .slider__content span:nth-child(n+2) i {
            color: #85DA7A;
            margin-right: 5px;
            font-size: 1.5rem; }
            @media (max-width: 1199.98px) {
              .banner__slider .slider__item .slider__content span:nth-child(n+2) i {
                font-size: 1.3rem; } }
            @media (max-width: 767.98px) {
              .banner__slider .slider__item .slider__content span:nth-child(n+2) i {
                font-size: 1.1rem; } }
  .banner__slider .slick-dots {
    position: absolute;
    bottom: 60px;
    list-style: none;
    width: 100%;
    text-align: center; }
    .banner__slider .slick-dots li {
      display: inline-block;
      border-radius: 50%; }
      .banner__slider .slick-dots li.slick-active button {
        background-color: #E42415;
        color: #E42415; }
      .banner__slider .slick-dots li button {
        background-color: #FFFFFF;
        color: #FFFFFF;
        border: none;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        display: block;
        margin: 0 5px; }
        .banner__slider .slick-dots li button:active, .banner__slider .slick-dots li button:focus {
          outline: none; }
        .banner__slider .slick-dots li button:hover {
          cursor: pointer; }

.banner {
  height: 400px;
  position: relative;
  overflow: hidden; }
  @media (max-width: 767.98px) {
    .banner {
      height: 240px; } }
  .banner.big {
    height: 520px; }
    @media (max-width: 767.98px) {
      .banner.big {
        height: 350px; } }
    .banner.big .banner__content .thumb img {
      height: auto; }
  .banner .banner__thumb {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0; }
    .banner .banner__thumb img {
      flex-shrink: 0;
      min-width: 100%;
      height: 100%;
      object-fit: cover; }
  .banner .banner__content {
    position: absolute;
    left: 0;
    bottom: 30px;
    width: 100%;
    top: 0; }
    .banner .banner__content span {
      font-size: 2rem;
      font-weight: 700;
      text-shadow: 1px 1px 1px #333;
      color: #ffffff; }
      @media (max-width: 767.98px) {
        .banner .banner__content span {
          font-size: 1.4rem; } }
    .banner .banner__content .thumb ~ .thumb__content {
      position: absolute;
      bottom: -15px;
      margin: 0 auto;
      left: 0;
      right: 0; }

.intro__block {
  padding: 50px 0;
  position: relative; }
  .intro__block p {
    font-size: 18px; }
  @media (max-width: 767.98px) {
    .intro__block {
      padding-bottom: 20px; } }
  .intro__block .custom__thumbnail {
    position: absolute;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%; }
  .intro__block .intro__content {
    margin-top: auto;
    margin-bottom: auto; }
  @media (max-width: 767.98px) {
    .intro__block h1 {
      font-size: 1.4rem; }
    .intro__block p {
      font-size: 1rem; }
    .intro__block .btn {
      margin-top: 15px; } }
  @media (max-width: 1199.98px) {
    .intro__block .intro__content {
      text-align: center;
      margin-bottom: 50px; } }

.product__category {
  text-align: center; }
  .product__category a {
    transition: all .2s ease; }
    .product__category a:not(.btn) {
      display: block;
      text-decoration: none;
      color: #383838; }
      .product__category a:not(.btn):hover {
        color: #CC1209; }
        .product__category a:not(.btn):hover .item__thumb img {
          transform: scale(1.05); }
  .product__category h2 {
    margin-bottom: -20px; }
  .product__category .btn {
    margin-top: 30px; }
  .product__category .category__item {
    margin-top: 40px;
    padding: 20px; }
    @media (max-width: 767.98px) {
      .product__category .category__item {
        padding: 0;
        margin-top: 15px; } }
    .product__category .category__item .item__thumb {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      flex: 1;
      position: relative;
      overflow: hidden;
      height: 100%;
      border-radius: 3px; }
      .product__category .category__item .item__thumb img {
        transition: all 300ms ease;
        object-fit: cover;
        width: auto;
        height: auto;
        display: block;
        margin: auto;
        padding: 10px; }
    .product__category .category__item span {
      font-weight: 600;
      font-size: 15px; }

.image__holder {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  flex: 1;
  position: relative;
  overflow: hidden;
  height: 100%;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15); }
  .image__holder img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover; }

.block .block__item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 80px;
  grid-row-gap: 80px;
  align-items: center; }
  @media (max-width: 991.98px) {
    .block .block__item {
      display: block; }
      .block .block__item .item__content {
        margin-bottom: 30px; } }
  .block .block__item:not(:last-child) {
    margin-bottom: 80px; }
    @media (max-width: 991.98px) {
      .block .block__item:not(:last-child) {
        margin-bottom: 40px; } }
  .block .block__item .item__thumb {
    position: relative;
    order: 1; }
    .block .block__item .item__thumb:not(.transparent) {
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15); }
    @media (max-width: 991.98px) {
      .block .block__item .item__thumb {
        margin-bottom: 30px; } }
    .block .block__item .item__thumb img {
      width: 100%;
      vertical-align: bottom;
      height: auto; }
    .block .block__item .item__thumb .thumb__overlay {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9; }
      .block .block__item .item__thumb .thumb__overlay i {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.7);
        color: #ffffff;
        border: 1px solid;
        text-align: center;
        line-height: 50px;
        font-size: 1.4rem;
        transition: all .2s ease; }
      .block .block__item .item__thumb .thumb__overlay:hover i {
        background-color: rgba(0, 0, 0, 0.9); }
  .block .block__item .item__content p {
    font-size: 1rem; }
  .block .block__item .item__content .btn {
    margin-top: 15px;
    margin-bottom: 0; }
  .block .block__item:nth-child(even) .item__content {
    order: 1; }

.block.reverse .block__item {
  grid-gap: 40px; }
  .block.reverse .block__item:nth-child(even) .item__thumb {
    order: 2; }

.icon__link {
  text-decoration: none;
  color: #E32219;
  font-weight: 600;
  font-size: 17px; }
  .icon__link i {
    margin-right: 10px; }

.btn {
  position: relative;
  display: inline-block;
  transition: all 150ms ease;
  font-weight: 400;
  font-size: 1rem !important;
  outline: none;
  line-height: normal !important;
  margin: 0;
  cursor: pointer; }
  .btn.red {
    background-color: #E32219;
    border-radius: 3px;
    color: #ffffff;
    padding: 12px 50px 12px 30px;
    text-decoration: none;
    font-weight: 600; }
    .btn.red::after {
      position: absolute;
      right: 30px;
      top: 17px;
      content: "\f054";
      font-family: "Font Awesome 5 Pro";
      font-weight: 500;
      font-size: 11pt;
      text-decoration: unset;
      transition: all 150ms ease; }
    .btn.red:hover {
      background-color: #C81D15; }
      .btn.red:hover::after {
        right: 25px; }
    .btn.red.icon {
      padding: 12px 30px; }
      .btn.red.icon i {
        margin-right: 10px; }
      .btn.red.icon::after {
        display: none; }

.c2a__full {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  padding: 70px 0;
  width: 100%;
  background-color: #ECEDF2;
  position: relative;
  overflow: hidden; }
  @media (max-width: 767.98px) {
    .c2a__full {
      padding: 50px 0; }
      .c2a__full .button_holder {
        text-align: center; }
        .c2a__full .button_holder:not(:last-child) {
          margin-bottom: 15px; } }
  .c2a__full .c2a__content {
    z-index: 2; }
    .c2a__full .c2a__content p {
      font-size: 1rem; }
    .c2a__full .c2a__content ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      .c2a__full .c2a__content ul li {
        line-height: 2.2rem; }
        .c2a__full .c2a__content ul li a {
          color: #383838;
          text-decoration: none; }
          .c2a__full .c2a__content ul li a i {
            margin-right: 5px;
            color: #E32219; }
    .c2a__full .c2a__content.check ul {
      padding-left: 35px; }
      .c2a__full .c2a__content.check ul li {
        position: relative;
        font-weight: 600; }
        .c2a__full .c2a__content.check ul li::before {
          position: absolute;
          left: -35px;
          top: 5px;
          content: "\f00c";
          font-family: "Font Awesome 5 Pro";
          color: #85DA7A;
          font-weight: 600;
          font-size: 12pt;
          text-decoration: unset;
          transition: all 150ms ease; }
  .c2a__full .thumb__wrapper {
    position: absolute;
    flex: 0 0 50%;
    max-width: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%; }
    @media (max-width: 991.98px) {
      .c2a__full .thumb__wrapper {
        display: none; } }
    .c2a__full .thumb__wrapper::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: #ecedf2;
      background: -moz-linear-gradient(left, #ecedf2 12%, rgba(236, 237, 242, 0.66) 42%, rgba(255, 255, 255, 0.57) 50%, rgba(255, 255, 255, 0) 100%);
      background: -webkit-gradient(left top, right top, color-stop(12%, #ecedf2), color-stop(42%, rgba(236, 237, 242, 0.66)), color-stop(50%, rgba(255, 255, 255, 0.57)), color-stop(100%, rgba(255, 255, 255, 0)));
      background: -webkit-linear-gradient(left, #ecedf2 12%, rgba(236, 237, 242, 0.66) 42%, rgba(255, 255, 255, 0.57) 50%, rgba(255, 255, 255, 0) 100%);
      background: -o-linear-gradient(left, #ecedf2 12%, rgba(236, 237, 242, 0.66) 42%, rgba(255, 255, 255, 0.57) 50%, rgba(255, 255, 255, 0) 100%);
      background: -ms-linear-gradient(left, #ecedf2 12%, rgba(236, 237, 242, 0.66) 42%, rgba(255, 255, 255, 0.57) 50%, rgba(255, 255, 255, 0) 100%);
      background: linear-gradient(to right, #ecedf2 12%, rgba(236, 237, 242, 0.66) 42%, rgba(255, 255, 255, 0.57) 50%, rgba(255, 255, 255, 0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ecedf2', endColorstr='#ffffff', GradientType=1 ); }
    .c2a__full .thumb__wrapper .c2a__thumb {
      position: relative;
      height: 100%; }
      .c2a__full .thumb__wrapper .c2a__thumb img {
        object-fit: cover;
        min-height: 100%;
        min-width: 100%;
        display: block; }
  .c2a__full .icon__link {
    display: block; }
    .c2a__full .icon__link:not(:first-child) {
      margin-top: 15px; }
  .c2a__full.small {
    padding: 40px 0; }

.dark__content ul {
  list-style: none;
  padding: 0;
  margin: 0; }

.dark__content.special > ul {
  padding-left: 35px; }
  .dark__content.special > ul > li {
    position: relative;
    line-height: 1.8rem; }
    .dark__content.special > ul > li:not(:last-child) {
      padding-bottom: 10px; }
    .dark__content.special > ul > li::before {
      position: absolute;
      left: -35px;
      top: 5px;
      content: "\f00c";
      font-family: "Font Awesome 5 Pro";
      color: #85DA7A;
      font-weight: 600;
      font-size: 12pt;
      text-decoration: unset;
      transition: all 150ms ease; }

@media (max-width: 767.98px) {
  .media__block .thumbnail {
    margin-bottom: 30px; } }

.media__block .media__content {
  padding: 0 0 30px 0; }
  .media__block .media__content + .thumbnail {
    margin-top: auto; }

.media__block .small .thumbnail {
  height: calc(50% - 15px); }
  .media__block .small .thumbnail:not(:last-child) {
    margin-bottom: 30px; }

@media (max-width: 767.98px) {
  .mt-100 {
    margin-top: 60px !important; } }

.thumbnail {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  flex: 1;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  -webkit-box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.49);
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.49);
  transition: all .2s ease; }
  .thumbnail:hover .thumbnail__thumb {
    overflow: hidden; }
    .thumbnail:hover .thumbnail__thumb img {
      transform: scale(1.05); }
  .thumbnail .thumbnail__thumb {
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .thumbnail .thumbnail__thumb img {
      transition: all .3s ease;
      object-fit: cover;
      min-height: 100%;
      min-width: 100%;
      display: block;
      height: auto; }
  .thumbnail .thumbnail__title {
    position: absolute;
    left: 0;
    bottom: 20px;
    padding: 8px 12px;
    font-size: 20px;
    font-weight: 600;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    z-index: 1;
    border-radius: 0px 3px 3px 0px;
    text-transform: uppercase; }
  .thumbnail.video::before {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    padding: 0;
    width: 65px;
    height: 65px;
    line-height: 65px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    border: 2px solid #ffffff;
    content: "\f03d";
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    font-size: 28px;
    z-index: 1;
    color: #ffffff;
    text-align: center; }
  .thumbnail.small .thumbnail__title {
    bottom: 15px;
    font-size: 16px;
    padding: 6px 15px; }
  .thumbnail.small.video::before {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    padding: 10px 12px;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    border: 2px solid #ffffff;
    content: "\f04b";
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    font-size: 16px;
    z-index: 1;
    color: #ffffff; }
  .thumbnail.big {
    border-radius: 30px 0 0 30px;
    height: 500px;
    width: auto;
    overflow: unset;
    position: relative; }
    .thumbnail.big .thumbnail__title {
      font-size: 24px;
      padding: 10px 30px; }
    .thumbnail.big .thumbnail__thumb {
      height: 100%;
      overflow: unset; }
      .thumbnail.big .thumbnail__thumb img {
        width: auto;
        vertical-align: bottom; }
    .thumbnail.big::after {
      border-radius: 30px 0 0 30px;
      width: auto; }
    .thumbnail.big.video .thumbnail__thumb img:after {
      width: 80px;
      height: 80px;
      font-size: 30px;
      padding: 20px 26px;
      border: 3px solid #ffffff; }

.products__pros {
  border-top: 1px solid #ECEDF2; }
  .products__pros.open .collapse__bar i {
    transform: rotate(180deg); }
  .products__pros.open .pro__content {
    display: block; }
  .products__pros .collapse__bar {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    padding: 25px 0;
    position: relative;
    cursor: pointer; }
    .products__pros .collapse__bar:hover h3 {
      color: #E32219; }
    .products__pros .collapse__bar h3 {
      margin: 0;
      transition: color .2s ease; }
      @media (max-width: 767.98px) {
        .products__pros .collapse__bar h3 {
          font-size: 1.1rem; } }
    .products__pros .collapse__bar i {
      color: #E32219;
      font-size: 26px;
      height: 18px; }
  .products__pros .pro__content {
    display: none;
    margin-bottom: 30px; }
    .products__pros .pro__content .pro__thumb {
      width: 100%;
      overflow: hidden;
      display: block;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15); }
      .products__pros .pro__content .pro__thumb img {
        transition: all 300ms ease;
        display: block;
        width: 100%; }
    .products__pros .pro__content ul {
      list-style: none;
      padding: 0; }
      @media (max-width: 767.98px) {
        .products__pros .pro__content ul {
          margin-bottom: 30px; } }
      .products__pros .pro__content ul li {
        position: relative;
        font-size: 1rem;
        font-weight: 600;
        padding: 5px 0 5px 20px; }
        .products__pros .pro__content ul li::before {
          content: "\f054";
          font-family: "Font Awesome 5 Pro";
          color: #E32219;
          font-weight: 500;
          font-size: 11pt;
          text-decoration: unset;
          transition: all 150ms ease;
          position: absolute;
          left: 0;
          top: 8px; }

.video__overview .item {
  margin-bottom: 30px; }

.photos__holder .photo__item {
  height: calc(100% - 30px);
  margin-bottom: 30px; }
  .photos__holder .photo__item .photo {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15); }
    .photos__holder .photo__item .photo img {
      transition: all 300ms ease;
      object-fit: cover;
      min-height: 100%;
      min-width: 100%;
      display: block; }

footer {
  position: relative;
  background-color: #ffffff;
  z-index: 1; }
  footer .wrapper {
    position: relative;
    overflow: visible;
    background-color: #383838;
    width: 100%;
    -webkit-box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
    color: #ffffff; }
  footer .bottom {
    margin-top: 30px;
    padding: 18px 0 10px;
    background-color: #2d2c2c;
    text-align: center; }
    footer .bottom ul {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 0;
      padding: 0; }
      footer .bottom ul li {
        float: left;
        font-size: 14px;
        margin: 0 7.5px 8px;
        line-height: 1rem;
        color: rgba(255, 255, 255, 0.85); }
        footer .bottom ul li a {
          color: rgba(255, 255, 255, 0.85);
          text-decoration: none; }
          footer .bottom ul li a:hover {
            color: #ffffff; }
  footer .logo {
    margin-top: -30px; }
    footer .logo img {
      width: 100%;
      max-width: 100%; }
  @media (max-width: 991.98px) {
    footer .wrapper {
      margin: 0; } }
  footer .sub__title h4 {
    font-size: 1.5rem;
    text-transform: uppercase; }
  footer h5 {
    font-weight: 600;
    margin: 0 0 10px 0;
    font-size: 1.1rem;
    min-height: 24px; }
  footer ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    footer ul li {
      line-height: 2rem; }
      footer ul li:last-child {
        padding-bottom: 0; }
  footer a {
    text-decoration: none;
    color: #ffffff; }
    footer a:hover {
      color: #E32219;
      text-decoration: none; }
  footer .contact__info {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 20px; }
    footer .contact__info span {
      display: block;
      margin-bottom: 5px;
      color: rgba(255, 255, 255, 0.5); }

.brands__holder {
  text-align: center;
  background-color: #383838;
  color: #ffffff;
  padding: 50px 0; }
  .brands__holder .brand:not(:last-child)::after {
    position: absolute;
    right: 0;
    height: 60%;
    top: 20%;
    width: 1px;
    background-color: #E5E5E5;
    content: ''; }
  .brands__holder .brand__thumb {
    margin-top: 30px; }
  .brands__holder .brand__content {
    margin-top: 20px; }
    .brands__holder .brand__content p {
      font-size: 16px; }

.history__nav {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: 30px 0;
  padding: 5px;
  overflow-y: auto; }
  .history__nav .nav__item {
    position: relative;
    flex: auto;
    padding: 8px 8px 2px;
    border-left: 2px solid #E32219;
    border-top: 2px solid #E32219;
    transition: .4s ease-in-out border;
    cursor: pointer; }
    @media (max-width: 767.98px) {
      .history__nav .nav__item {
        min-width: 65px; } }
    .history__nav .nav__item::before {
      content: '';
      position: absolute;
      top: -6px;
      left: -6px;
      height: 10px;
      border-radius: 50%;
      width: 10px;
      background-color: #E32219; }
    .history__nav .nav__item.active {
      border-left-color: #E32219;
      border-top-color: #383838;
      color: #E32219;
      font-weight: 700; }
      .history__nav .nav__item.active::before {
        background-color: #E32219; }
      .history__nav .nav__item.active ~ div {
        border-color: #383838; }
        .history__nav .nav__item.active ~ div::before {
          background-color: #383838; }
    .history__nav .nav__item:last-child {
      flex: unset;
      padding-right: 0;
      border-top-color: transparent !important; }

.history {
  margin: 80px 0; }
  .history .slick-arrow {
    height: auto;
    border: 0;
    z-index: 1;
    background-color: transparent;
    margin: auto;
    color: #3b3b3b;
    font-size: 50px;
    transition: all .2s ease;
    cursor: pointer; }
    .history .slick-arrow.slick-disabled {
      display: none !important; }
    .history .slick-arrow:focus {
      outline: none; }
    .history .slick-arrow:hover {
      background-color: transparent; }
  .history button.slick-arrow.slick-prev {
    left: -30px; }
    .history button.slick-arrow.slick-prev:hover {
      padding-left: 0px; }
  .history button.slick-arrow.slick-next {
    right: -30px; }
    .history button.slick-arrow.slick-next:hover {
      padding-right: 0px; }
  .history .item {
    margin: 15px;
    outline: none; }
    .history .item.slick-active .item__inner {
      opacity: 1; }
  .history .history__item {
    margin-top: 0px;
    border-radius: 3px;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    height: 300px; }
    @media (max-width: 767.98px) {
      .history .history__item {
        height: auto; } }
    .history .history__item .item__inner {
      width: 100%;
      margin: auto;
      text-align: left;
      opacity: 0.6;
      height: 100%; }
      .history .history__item .item__inner > div {
        height: 100%; }
        .history .history__item .item__inner > div > .height {
          height: 100%; }
    .history .history__item .history__thumb {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      position: relative;
      overflow: hidden;
      flex: 1;
      width: 100%;
      height: 100%;
      max-height: 360px;
      background-color: #ecedf2; }
      .history .history__item .history__thumb img {
        flex-shrink: 0;
        min-width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
    .history .history__item .history__content {
      padding-right: 30px; }
      @media (max-width: 767.98px) {
        .history .history__item .history__content {
          padding: 30px; } }
      .history .history__item .history__content span {
        font-size: 22px;
        display: block;
        margin-bottom: 15px;
        font-weight: 700;
        color: #E32219; }

.factsnumbers {
  background-color: #383838;
  padding: 60px 0;
  color: #ffffff; }
  .factsnumbers h2 {
    text-align: center; }
  .factsnumbers .mobile-support {
    display: none; }
    @media (max-width: 767.98px) {
      .factsnumbers .mobile-support {
        display: block;
        text-align: right;
        color: #ffffff; }
        .factsnumbers .mobile-support::before {
          content: "\f25a";
          font-family: "Font Awesome 5 Pro";
          font-weight: 100;
          display: inline-block;
          transform: rotate(30deg);
          -ms-transform: rotate(30deg);
          -webkit-transform: rotate(30deg);
          color: #E32219;
          margin-right: 5px; } }
  .factsnumbers table {
    border-collapse: collapse;
    margin: 30px auto 0; }
    @media (max-width: 767.98px) {
      .factsnumbers table {
        overflow-x: scroll;
        white-space: nowrap;
        display: flow-root; } }
    .factsnumbers table thead th {
      font-weight: 600;
      text-align: center;
      padding-bottom: 15px;
      color: #ffffff;
      border-right: 2px solid #E1E1E1; }
    .factsnumbers table tbody tr td {
      text-align: center;
      width: 80px;
      height: 60px;
      border: 2px solid #E1E1E1;
      color: #ffffff; }
      .factsnumbers table tbody tr td:first-child {
        padding-right: 20px;
        text-align: right;
        border: 0px;
        border-right: 5px solid #E32219;
        font-weight: 600; }
    @media (max-width: 991.98px) {
      .factsnumbers table tr th, .factsnumbers table tr td {
        padding: 10px; } }

.search__holder {
  position: relative; }
  .search__holder input[type="text"] {
    width: 100%;
    height: 50px;
    border-radius: 3px;
    border: none;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    padding: 0 20px;
    font-size: 16px; }
    .search__holder input[type="text"]:focus {
      outline: none; }
  .search__holder button[type="submit"] {
    height: 50px;
    width: 50px;
    position: absolute;
    right: 0;
    border: none;
    border-radius: 3px; }
    .search__holder button[type="submit"] i {
      font-size: 17px; }
    .search__holder button[type="submit"]:focus {
      outline: none; }
      .search__holder button[type="submit"]:focus i {
        color: red; }

.breadcrumbs {
  margin: 15px 0 50px; }
  .breadcrumbs > span {
    margin-right: 10px; }
  .breadcrumbs span {
    color: #E32219;
    font-weight: 600; }
    .breadcrumbs span a {
      text-decoration: none;
      color: #383838;
      font-weight: 500; }
      .breadcrumbs span a:hover {
        color: #E32219; }
      .breadcrumbs span a i {
        margin: 0; }
  .breadcrumbs i {
    margin-right: 10px; }

.dark__content .breadcrumbs {
  margin: 0 0 30px 0; }
  .dark__content .breadcrumbs span {
    color: #ffffff; }

.dark__content.inverse .breadcrumbs span {
  color: #383838; }

.dark__content p a:not(.btn),
.dark__content .wp-block-column a:not(.btn) {
  color: #ffffff;
  text-decoration: none; }
  .dark__content p a:not(.btn):hover,
  .dark__content .wp-block-column a:not(.btn):hover {
    color: #E32219; }

.category__intro p {
  font-size: 16px;
  margin-bottom: 0; }

.category__page .category__holder {
  margin-top: 50px; }
  @media (min-width: 768px) {
    .category__page .category__holder:not(:nth-child(3n))::after {
      position: absolute;
      right: 0;
      height: 40%;
      top: 50%;
      width: 1px;
      background-color: #E5E5E5;
      content: ''; } }
  .category__page .category__holder a {
    color: #383838;
    text-decoration: none; }
    .category__page .category__holder a:hover .category__content span {
      color: #E32219; }
      .category__page .category__holder a:hover .category__content span.more {
        text-decoration: underline; }
  .category__page .category__holder .category__thumb {
    text-align: center;
    display: block;
    height: 175px; }
    .category__page .category__holder .category__thumb img {
      width: 100%;
      max-width: 250px;
      margin: 0 auto;
      max-height: 100%;
      object-fit: cover; }
  .category__page .category__holder .category__content {
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;
    text-align: left;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: calc(100% - 175px); }
    .category__page .category__holder .category__content span {
      font-weight: 600; }
    .category__page .category__holder .category__content p {
      font-size: 14px; }
    .category__page .category__holder .category__content ul {
      list-style: none;
      margin: 15px 0; }
      .category__page .category__holder .category__content ul li {
        position: relative;
        padding: 3px 0; }
        .category__page .category__holder .category__content ul li::before {
          position: absolute;
          left: -35px;
          top: 5px;
          content: "\f054";
          font-family: "Font Awesome 5 Pro";
          color: #E32219;
          font-weight: 500;
          font-size: 12pt;
          text-decoration: unset;
          transition: all 150ms ease; }
    .category__page .category__holder .category__content span {
      transition: .3s ease all; }
      .category__page .category__holder .category__content span.more {
        color: #E32219;
        font-weight: 600;
        display: block;
        margin-top: auto; }

.chart__wrapper {
  overflow-x: auto; }

.product__intro {
  position: relative; }
  .product__intro .content__holder {
    position: relative;
    z-index: 1; }
  .product__intro .specs {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%; }
  .product__intro .pros__holder {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    text-align: left;
    color: #fff;
    background-color: #383838; }
    .product__intro .pros__holder .pros__content {
      padding: 0 30px; }
    @media (min-width: 768px) {
      .product__intro .pros__holder {
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%; } }
    @media (max-width: 767.98px) {
      .product__intro .pros__holder {
        padding: 30px;
        margin: 15px 0 30px;
        align-items: end; } }
    .product__intro .pros__holder span {
      font-size: 20px;
      display: block;
      font-weight: 400; }
      .product__intro .pros__holder span.title {
        font-weight: 700;
        font-size: 24px; }
    .product__intro .pros__holder ul {
      list-style: none;
      margin-bottom: 0; }
      .product__intro .pros__holder ul li {
        position: relative;
        font-size: 18px;
        padding: 5px 0; }
        .product__intro .pros__holder ul li::before {
          position: absolute;
          left: -35px;
          top: 8px;
          content: "\f324";
          font-family: "Font Awesome 5 Pro";
          color: #fff;
          font-weight: 300;
          font-size: 13pt;
          text-decoration: unset;
          transition: all 150ms ease; }

.feature__bar {
  width: 100%;
  background-color: #ECEDF2;
  padding: 50px 0; }
  @media (max-width: 767.98px) {
    .feature__bar {
      padding-bottom: 20px; } }
  .feature__bar .feature__holder {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; }
    @media (max-width: 767.98px) {
      .feature__bar .feature__holder {
        margin-bottom: 30px;
        justify-content: end; } }
    .feature__bar .feature__holder .icon {
      font-size: 42px; }
      @media (max-width: 767.98px) {
        .feature__bar .feature__holder .icon {
          font-size: 24px; } }
    .feature__bar .feature__holder .specs {
      padding-left: 20px; }
      .feature__bar .feature__holder .specs span {
        display: block;
        font-size: 16px;
        font-weight: 600; }
        .feature__bar .feature__holder .specs span:first-child {
          font-size: 24px;
          font-weight: 700;
          color: #E32219; }
          @media (max-width: 767.98px) {
            .feature__bar .feature__holder .specs span:first-child {
              font-size: 20px; } }
        @media (max-width: 767.98px) {
          .feature__bar .feature__holder .specs span {
            font-size: 15px; } }

.gallery__holder h2, .gallery__holder h3, .gallery__holder h4 {
  margin-bottom: 30px; }

.gallery__holder .col-md-12 .col-md-6 {
  order: 1; }

.gallery__holder .special:nth-child(even) .col-md-6:first-child {
  order: 2; }

.gallery__holder a {
  display: block;
  height: 100%;
  max-height: 170px;
  margin-bottom: 30px; }
  .gallery__holder a.medium_large {
    max-height: 370px; }
  .gallery__holder a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 3px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15); }

.page-scroll {
  display: inline-block;
  color: #383838;
  text-decoration: none; }
  .page-scroll span {
    display: inline-block;
    margin-right: 10px; }
    @media (max-width: 767.98px) {
      .page-scroll span {
        display: block;
        margin: 0 0 15px 0; } }
  .page-scroll .mouse-anim {
    margin: 0;
    display: inline-block;
    width: 26px;
    height: 44px;
    border: 2px solid #383838;
    border-radius: 14px;
    vertical-align: middle; }
    .page-scroll .mouse-anim .scroll {
      width: 4px;
      height: 4px;
      border-radius: 2px;
      border: 2px solid #383838;
      position: relative;
      left: 9px;
      -webkit-animation: scrolls 1.3s ease-out infinite; }

.full__slider {
  position: relative;
  display: block;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  height: 300px; }
  .full__slider .slick-list,
  .full__slider .slick-track {
    height: 100%; }
  .full__slider .slick-arrow {
    position: absolute;
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
    border-radius: 50%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    bottom: 0;
    margin: auto;
    color: #fff;
    font-size: 25px;
    transition: all .2s ease;
    cursor: pointer; }
    .full__slider .slick-arrow:focus {
      outline: none; }
  .full__slider .slick-prev {
    left: 20px; }
    .full__slider .slick-prev:hover {
      padding-left: 0px; }
  .full__slider .slick-next {
    right: 20px; }
    .full__slider .slick-next:hover {
      padding-right: 0px; }
  .full__slider .slider__item {
    position: relative;
    display: inline-block;
    float: left;
    margin: 0; }
    .full__slider .slider__item::after {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #fff; }
    .full__slider .slider__item img {
      width: auto;
      height: 100%;
      max-height: 100%;
      object-fit: cover; }

@media (min-width: 768px) {
  .content__holder.inverse .dark__content {
    padding: 50px 50px 50px 100px; } }

.content__holder.inverse .col-md-8 {
  order: 2; }

.content__holder.inverse .thumb__wrap {
  margin-right: auto;
  margin-left: 50px; }

.content__holder .thumb__wrap {
  margin-left: -50px;
  width: 100%;
  position: relative;
  padding: 50px 0; }
  .content__holder .thumb__wrap img {
    width: 100%;
    height: auto;
    vertical-align: text-bottom; }
  .content__holder .thumb__wrap .block__item {
    position: relative; }
    .content__holder .thumb__wrap .block__item:first-child:not(:last-child) {
      margin-bottom: 30px; }
  .content__holder .thumb__wrap .thumb__desc {
    position: absolute;
    top: 100%;
    right: 0;
    text-align: right; }
    .content__holder .thumb__wrap .thumb__desc span {
      display: block;
      padding-left: 30px; }
      @media (max-width: 991.98px) {
        .content__holder .thumb__wrap .thumb__desc span {
          padding: 15px; } }
  @media (max-width: 991.98px) {
    .content__holder .thumb__wrap {
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding: 0; } }

#gform_wrapper_2 {
  display: none; }

#gform_confirmation_wrapper_2.gform_confirmation_wrapper {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 999999;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 4px;
  color: #35ad35;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  border: 1px solid #ecedf2;
  border-left: 4px solid #35ad35; }

/* INTRO WP STYLING */
.intro__holder h5 {
  font-size: 1rem;
  margin: 0;
  display: block; }

.intro__holder p {
  margin: 10px 0 16px; }

.intro__holder ul {
  list-style: none;
  padding: 0px; }
  .intro__holder ul li {
    font-size: 16px;
    margin-top: 5px; }
    .intro__holder ul li a {
      color: #E32219;
      text-decoration: none; }
      .intro__holder ul li a[href^="tel"]:before {
        content: "\f879";
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        color: #000;
        margin-right: 5px; }
      .intro__holder ul li a[href^="mailto"]:before {
        content: "\f0e0";
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        color: #000;
        margin-right: 5px; }
      .intro__holder ul li a[href^="https"]:before {
        content: "\f0ac";
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        color: #000;
        margin-right: 5px; }

.intro__holder .wp-block-columns {
  margin-bottom: 0px; }
  .intro__holder .wp-block-columns .wp-block-image {
    margin: 0px; }
    .intro__holder .wp-block-columns .wp-block-image img {
      height: 100%; }

@media (min-width: 782px) {
  .intro__holder .wp-block-column:not(:first-child) {
    margin-left: 30px; } }

/* BASHING STYLING */
.contact__holder h5 {
  font-size: 1rem;
  margin: 0;
  display: block; }

.contact__holder ul {
  margin-top: 10px;
  list-style: none;
  padding: 0; }
  .contact__holder ul li {
    font-size: 16px;
    margin-top: 5px; }
    .contact__holder ul li a {
      color: #E32219;
      text-decoration: none; }
      .contact__holder ul li a i {
        color: #000;
        margin-right: 5px; }

.sale__intro h2 {
  margin-bottom: 0; }

.sale__intro ul {
  list-style: none;
  padding: 0; }
  .sale__intro ul li {
    padding: 3px 0; }
    .sale__intro ul li a {
      color: #E32219;
      text-decoration: none; }
      .sale__intro ul li a i {
        color: #000;
        padding-right: 5px; }

.sale__members .member__holder {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: bottom;
  -moz-align-items: bottom;
  -ms-align-items: bottom;
  align-items: bottom;
  margin-bottom: 50px; }
  .sale__members .member__holder .member__thumb {
    display: inline-block;
    flex: 1 1 40%;
    overflow: hidden;
    border-radius: 3px 3px 0px 3px;
    min-height: 235px; }
    .sale__members .member__holder .member__thumb img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top; }
  .sale__members .member__holder .member__content {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid #D5D5D5;
    padding: 0 20px;
    position: relative;
    flex: 1 1 60%; }
    .sale__members .member__holder .member__content .function__info {
      margin-top: 10px; }
      .sale__members .member__holder .member__content .function__info .function {
        font-size: 18px;
        font-weight: 700; }
      .sale__members .member__holder .member__content .function__info table {
        margin-top: 5px;
        border-collapse: collapse;
        margin-bottom: 20px; }
        .sale__members .member__holder .member__content .function__info table tr td {
          padding: 0;
          margin: 0;
          font-size: 14px;
          line-height: 1.4rem; }
    .sale__members .member__holder .member__content .contact__info h5 {
      font-size: .9rem;
      font-weight: 600;
      display: block;
      margin: 0 0 10px; }
    .sale__members .member__holder .member__content .contact__info ul {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 14px;
      margin-bottom: 10px; }
      .sale__members .member__holder .member__content .contact__info ul li {
        padding-top: 3px; }
        .sale__members .member__holder .member__content .contact__info ul li a {
          color: #E32219;
          text-decoration: none; }
          .sale__members .member__holder .member__content .contact__info ul li a i {
            color: #000;
            margin-right: 10px; }

.partner__holder .partner__item {
  margin-bottom: 50px; }
  .partner__holder .partner__item .partner__content {
    margin-left: 20px;
    border-left: 1px solid #E3E3E3;
    padding: 15px 20px 0 20px; }
    .partner__holder .partner__item .partner__content a.route {
      text-decoration: none; }
      .partner__holder .partner__item .partner__content a.route i {
        margin-right: 5px; }
        .partner__holder .partner__item .partner__content a.route i::after {
          opacity: 1; }
    .partner__holder .partner__item .partner__content h5 {
      margin: 0;
      font-size: 1.1rem; }
      .partner__holder .partner__item .partner__content h5 + span {
        display: block;
        font-size: .9rem; }
    .partner__holder .partner__item .partner__content .country {
      font-weight: 600;
      font-size: 16px; }
    .partner__holder .partner__item .partner__content .adress {
      padding: 0;
      list-style: none;
      min-height: 100px; }
      .partner__holder .partner__item .partner__content .adress li {
        line-height: 1.5rem;
        font-size: .9rem; }
        .partner__holder .partner__item .partner__content .adress li:first-child {
          font-weight: 600; }
    .partner__holder .partner__item .partner__content .contact {
      padding: 0;
      list-style: none; }
      .partner__holder .partner__item .partner__content .contact li {
        line-height: 1.5rem;
        font-size: .9rem; }
        .partner__holder .partner__item .partner__content .contact li a {
          text-decoration: none;
          color: #E32219;
          font-weight: 600; }
          .partner__holder .partner__item .partner__content .contact li a i {
            color: #000;
            margin-right: 10px; }

.list__style h4 {
  font-size: 1.2rem; }

.list__style ul {
  padding: 0;
  list-style: none;
  margin: 0; }
  .list__style ul li {
    line-height: 1.5rem;
    font-size: 1rem; }
    .list__style ul li a {
      text-decoration: none;
      color: #E32219;
      font-weight: 600; }
      .list__style ul li a i {
        color: #000;
        margin-right: 10px; }

.maps__holder {
  height: 375px;
  overflow: hidden;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15); }

@media (max-width: 991.98px) {
  .contact__list {
    padding: 30px 45px; } }

.contact__list .contact__info {
  margin-bottom: 15px; }

.qr .qr__heading {
  background-color: #2d2c2c;
  padding: 15px;
  text-align: center;
  font-size: 22px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 30px; }

input,
textarea,
select {
  font-family: 'Open Sans', sans-serif; }

.modal__holder {
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  display: none; }
  @media (min-width: 768px) {
    .modal__holder {
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; } }
  @media (max-width: 991.98px) {
    .modal__holder {
      padding: 30px; } }
  .modal__holder.open {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
  .modal__holder .modal__wrapper {
    position: relative;
    background-color: #ffffff;
    width: 600px;
    border-radius: 3px;
    overflow: hidden; }
    @media (max-width: 991.98px) {
      .modal__holder .modal__wrapper {
        overflow: scroll; } }
    .modal__holder .modal__wrapper .close__button {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      position: absolute;
      width: 40px;
      height: 40px;
      background-color: rgba(56, 56, 56, 0.9);
      right: 0;
      top: 0;
      border-radius: 0 0 0 3px;
      cursor: pointer; }
      .modal__holder .modal__wrapper .close__button i {
        color: #fff;
        font-size: 24px; }
    .modal__holder .modal__wrapper .modal__thumb img {
      width: 100%; }
    .modal__holder .modal__wrapper .modal__content {
      padding: 30px; }
      .modal__holder .modal__wrapper .modal__content h2 {
        margin: 0; }
      .modal__holder .modal__wrapper .modal__content span,
      .modal__holder .modal__wrapper .modal__content .gsection_title {
        display: block;
        font-weight: 600;
        font-size: 18px;
        margin-top: 30px; }
      .modal__holder .modal__wrapper .modal__content p {
        font-size: 16px; }
      .modal__holder .modal__wrapper .modal__content .gform_fields {
        list-style: none;
        padding: 0;
        margin: 0; }
      .modal__holder .modal__wrapper .modal__content button {
        border: 0;
        margin-top: 30px;
        font-family: 'Open Sans', sans-serif;
        position: relative; }
      .modal__holder .modal__wrapper .modal__content .input__field {
        position: relative;
        width: 100%; }
        .modal__holder .modal__wrapper .modal__content .input__field.hidden_label .gfield_label {
          display: none; }
        .modal__holder .modal__wrapper .modal__content .input__field .ginput_container {
          margin-top: 20px; }
        .modal__holder .modal__wrapper .modal__content .input__field.gfield_contains_required .ginput_container {
          position: relative; }
          .modal__holder .modal__wrapper .modal__content .input__field.gfield_contains_required .ginput_container::after {
            content: '*';
            font-size: 16px;
            top: 10px;
            right: 0;
            padding-right: 10px;
            padding-top: 3px;
            position: absolute;
            color: #E98884; }
        .modal__holder .modal__wrapper .modal__content .input__field textarea {
          font-family: 'Open Sans', sans-serif;
          color: #383838;
          margin: 0;
          position: relative;
          font-size: .9rem;
          width: 100%;
          border-radius: 3px;
          background-color: #F0F0F0;
          border: 1px solid #E8E8E8;
          font-size: 14px;
          padding: 10px;
          color: #383838; }
          .modal__holder .modal__wrapper .modal__content .input__field textarea:focus, .modal__holder .modal__wrapper .modal__content .input__field textarea:active {
            outline: none; }
        .modal__holder .modal__wrapper .modal__content .input__field input[type="text"] {
          font-family: 'Open Sans', sans-serif;
          color: #383838;
          margin: 0;
          position: relative;
          font-size: .9rem;
          width: 100%;
          height: 45px;
          border-radius: 3px;
          background-color: #F0F0F0;
          border: 1px solid #E8E8E8;
          padding: 0 25px 0 10px;
          color: #383838; }
          .modal__holder .modal__wrapper .modal__content .input__field input[type="text"]:focus, .modal__holder .modal__wrapper .modal__content .input__field input[type="text"]:active {
            outline: none; }
        .modal__holder .modal__wrapper .modal__content .input__field .required {
          font-size: 16px;
          top: 0;
          right: 0;
          padding-right: 10px;
          padding-top: 3px;
          position: absolute;
          color: #E98884; }
      .modal__holder .modal__wrapper .modal__content .note {
        font-size: 14px; }
        .modal__holder .modal__wrapper .modal__content .note span {
          font-size: 14px;
          display: inline-block;
          color: #E32219;
          margin: 10px 0 10px 0; }

.news__holder h1 {
  margin-bottom: 0; }

.news__holder .date {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 30px;
  display: block; }

.news__holder p {
  font-size: 16px;
  margin-bottom: 30px; }

.news__holder blockquote {
  display: block;
  margin: 30px 0;
  padding: 5px 15px;
  font-size: 18px;
  font-weight: 600;
  border-left: 10px solid #E32219; }

.news__article {
  position: relative;
  border-radius: 3px;
  margin-bottom: 30px;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15); }
  .news__article .news__thumb {
    width: 100%;
    height: 200px;
    overflow: hidden; }
    .news__article .news__thumb img {
      transition: all 700ms ease;
      width: 100%;
      vertical-align: bottom;
      object-fit: cover;
      height: 100%; }
    .news__article .news__thumb::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background: -webkit-linear-gradient(bottom, black 0%, rgba(0, 0, 0, 0) 40%);
      background: -o-linear-gradient(bottom, black 0%, rgba(0, 0, 0, 0) 40%);
      background: linear-gradient(to top, black 0%, rgba(0, 0, 0, 0) 40%);
      z-index: 1; }
  .news__article .news__content {
    position: absolute;
    bottom: 0;
    border-bottom: 4px solid #E32219;
    padding: 10px 0px 10px 20px;
    z-index: 2;
    transition: padding 700ms ease;
    width: 100%; }
    .news__article .news__content .date {
      font-size: 14px;
      color: #fff;
      opacity: 0.7;
      margin-bottom: 5px;
      font-weight: 500;
      display: block; }
    .news__article .news__content .title {
      color: #fff;
      font-size: 16px;
      font-weight: 600; }
  .news__article:hover .news__content {
    padding-right: 20px; }
  .news__article:hover .news__thumb {
    overflow: hidden;
    transition: all 700ms ease; }
    .news__article:hover .news__thumb img {
      transition: all 700ms ease;
      transform: scale(1.1); }

@media (min-width: 768px) {
  .sidebar__holder {
    padding-left: 50px; } }

.sidebar__holder .news__thumb {
  height: 100%; }
  .sidebar__holder .news__thumb img {
    height: auto; }

.sidebar__holder .person {
  background-color: #383838;
  border-radius: 3px;
  width: 100%;
  color: #fff;
  padding: 20px; }
  .sidebar__holder .person .function {
    display: block;
    font-size: 18px;
    font-weight: 700; }
  .sidebar__holder .person .name {
    display: block;
    font-size: 16px;
    font-weight: 700; }
  .sidebar__holder .person table {
    margin-top: 5px;
    border-collapse: collapse;
    margin-bottom: 20px; }
    .sidebar__holder .person table tr td {
      padding: 0;
      margin: 0;
      font-size: 14px; }
      .sidebar__holder .person table tr td:nth-child(odd) {
        padding-right: 20px; }
  .sidebar__holder .person ul {
    list-style: none;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 0; }
    .sidebar__holder .person ul li a {
      text-decoration: none;
      color: #fff;
      font-size: 14px; }
      .sidebar__holder .person ul li a i {
        color: #E32219;
        margin-right: 10px; }

.sidebar__holder .c2a {
  background-color: #383838;
  border-radius: 3px;
  width: 100%;
  color: #fff;
  padding: 30px; }
  .sidebar__holder .c2a h3 {
    font-size: 1.2rem;
    font-weight: 600; }
  .sidebar__holder .c2a ul {
    list-style: none;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 0; }
    .sidebar__holder .c2a ul li:not(:last-child) {
      padding-bottom: 10px; }
    .sidebar__holder .c2a ul li a {
      text-decoration: none;
      color: #fff;
      font-size: 1rem; }
      .sidebar__holder .c2a ul li a i {
        color: #E32219;
        margin-right: 10px; }

.sidebar__holder .category {
  text-align: center; }
  .sidebar__holder .category span {
    margin-top: -15px;
    display: block;
    font-weight: 600;
    font-size: 18px; }

.sidebar__holder .recent__news {
  background-color: #ECEDF2;
  border-radius: 3px;
  padding: 30px; }
  .sidebar__holder .recent__news .title {
    font-size: 18px;
    font-weight: 700;
    color: #383838; }
  .sidebar__holder .recent__news ul {
    list-style: none;
    padding: 0; }
    .sidebar__holder .recent__news ul li:not(:last-child) {
      padding-bottom: 15px; }
    .sidebar__holder .recent__news ul li a {
      text-decoration: none;
      color: #5C5C5D; }
      .sidebar__holder .recent__news ul li a .date {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0; }
      .sidebar__holder .recent__news ul li a .news_title {
        font-size: 16px;
        font-weight: 600; }
      .sidebar__holder .recent__news ul li a:hover {
        color: #E32219; }
  .sidebar__holder .recent__news .view_link {
    margin-top: 15px;
    position: relative;
    color: #E32219;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none; }
    .sidebar__holder .recent__news .view_link::after {
      position: absolute;
      right: -15px;
      top: 5px;
      content: "\f054";
      font-family: "Font Awesome 5 Pro";
      font-weight: 500;
      font-size: 12px;
      text-decoration: unset;
      transition: all 150ms ease; }
    .sidebar__holder .recent__news .view_link:hover::after {
      right: -20px; }

.share__buttons > * {
  display: inline-block;
  vertical-align: middle; }
  .share__buttons > *:not(:first-child) {
    margin-left: 10px; }

.share__buttons span {
  color: #646464; }

.share__buttons a {
  color: #E32219;
  font-size: 1.4rem;
  transition: all .2s ease;
  text-decoration: none; }
  .share__buttons a:hover {
    transition: all .2s ease;
    color: #383838; }

.h-full {
  height: 100%; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.v-bottom {
  margin-top: auto; }

.slick-active:focus, .slick-active:active {
  outline: none; }

.fancybox-button--send {
  font-family: 'Open Sans', sans-serif;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 230px;
  bottom: 30px;
  display: inline-block;
  transition: all 150ms ease;
  font-weight: 400;
  font-size: 1rem;
  outline: none;
  line-height: normal;
  margin: auto;
  background-color: #E32219;
  border-radius: 3px;
  color: #ffffff;
  padding: 12px 0;
  text-decoration: none;
  font-weight: 600; }
  .fancybox-button--send:hover {
    background-color: #C81D15; }

.same__height .thumbnail {
  height: 300px; }
  @media (max-width: 991.98px) {
    .same__height .thumbnail {
      height: 200px; } }
  @media (max-width: 767.98px) {
    .same__height .thumbnail {
      height: auto; } }

.gradient__bg {
  padding-top: 50px;
  background: #f8f8f8;
  background: -moz-linear-gradient(top, #f8f8f8 0%, white 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #f8f8f8), color-stop(100%, white));
  background: -webkit-linear-gradient(top, #f8f8f8 0%, white 100%);
  background: -o-linear-gradient(top, #f8f8f8 0%, white 100%);
  background: -ms-linear-gradient(top, #f8f8f8 0%, white 100%);
  background: linear-gradient(to bottom, #f8f8f8 0%, white 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f8f8', endColorstr='#ffffff', GradientType=0 ); }

.acf-map {
  width: 100%;
  height: 100%; }
  .acf-map img {
    max-width: inherit !important; }

.team_cat {
  display: none; }

.dark__content {
  background-color: #383838;
  padding: 50px 100px 50px 50px;
  color: #ffffff;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .dark__content.inverse {
    background-color: #ffffff;
    color: #383838; }
  @media (max-width: 767.98px) {
    .dark__content {
      padding: 30px; } }

.org img {
  width: 100%;
  height: auto;
  max-width: 100%; }
